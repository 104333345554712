<template>
  <section class="section">
    <div class="container">
      <div class="columns is-multiline is-mobile is-tablet is-desktop">
        <div class="column is-12-mobile is-12-tablet is-12-desktop">
          <p class="has-text-left is-size-5 has-text-weight-bold">
            下記以外に「アドオン」と呼ばれる上乗せ型のリワードがあります。
            詳しくは
            <router-link to="/what-is-add-on#page-top">こちら</router-link> へ。
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style scoped></style>
