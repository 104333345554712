var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-multiline is-mobile is-tablet is-desktop"},[_c('div',{staticClass:"column is-12-mobile is-12-tablet is-12-desktop"},[_vm._m(0),_vm._m(1),_c('img',{attrs:{"src":"/images/broken_image.png"}}),_c('img',{attrs:{"src":"/images/broken_image.png"}}),_vm._v(" ＜br >¥DATA¥00_INIT¥SOUND.573> "),_c('br'),_c('br'),_vm._m(2),_vm._m(3),_c('p',[_vm._v("の作品です。")]),_c('br'),_c('p',[_vm._v("当サイトは、非公式・非公認のファンサイトです。")]),_vm._m(4),_c('br'),_vm._m(5),_c('br'),_c('p',[_vm._v("当サイトはリンクフリーです☆彡")]),_c('br'),_c('button',{staticClass:"button is-rounded"},[_c('router-link',{staticClass:"has-text-weight-bold is-size-5",attrs:{"to":"/"}},[_vm._v("トップページへ戻る")])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_c('a',{attrs:{"href":"https://support.microsoft.com/ja-jp/help/17621/internet-explorer-downloads","target":"_blank"}},[_c('img',{attrs:{"src":"/images/msie_now.png","alt":"IE Now!","title":"Internet Explorer Now!","width":"140"}})]),_vm._v("   "),_c('a',{attrs:{"href":"https://www.mozilla.org/ja/firefox/new/","target":"_blank"}},[_c('img',{attrs:{"src":"/images/netscape_now.gif","width":"140","alt":"Netscape Now!","title":"Netscape Now!"}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_c('img',{attrs:{"src":"/images/broken_image.png"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v(" 「"),_c('a',{attrs:{"href":"https://rabbitandbearstudios.com/eiyudenchronicle/","target":"_blank"}},[_vm._v("百英雄伝")]),_vm._v("」は ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_c('a',{attrs:{"href":"https://rabbitandbearstudios.com/","target":"_blank"}},[_vm._v("Rabbit & Bear Studios 株式会社 さん")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v(" Inspired by "),_c('a',{attrs:{"href":"http://www.geocities.co.jp/Bookend/2172/","target":"_blank"}},[_vm._v("幻想水滸伝攻略真書")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_c('a',{staticClass:"camouflage-link",attrs:{"href":"https://jbbs.shitaraba.net/game/61322/","target":"_blank"}},[_vm._v("百英雄伝BBS")]),_vm._v("・同盟・ウェブリングは準備中です m(_ _)m ")])
}]

export { render, staticRenderFns }