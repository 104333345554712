export default {
  strict: true,
  namespaced: true,
  state: {
    nameARivalBeigomaTrainer: 'whichever',
  },
  mutations: {
    setNameARivalBeigomaTrainer(state, value) {
      state.nameARivalBeigomaTrainer = value
    },
  },
}
