import { render, staticRenderFns } from "./Bottom.vue?vue&type=template&id=447b61ec&scoped=true&"
import script from "./Bottom.vue?vue&type=script&lang=js&"
export * from "./Bottom.vue?vue&type=script&lang=js&"
import style0 from "./Bottom.vue?vue&type=style&index=0&id=447b61ec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "447b61ec",
  null
  
)

export default component.exports