<template>
  <section class="section">
    <div class="container">
      <div class="columns is-multiline is-mobile is-tablet is-desktop">
        <div class="column is-12-mobile is-12-tablet is-12-desktop">
          <h1 class="title">ストレッチゴール一覧</h1>
        </div>
      </div>
      <div class="columns is-multiline is-mobile is-tablet is-desktop">
        <div class="column is-12-mobile is-12-tablet is-12-desktop">
          <div class="content has-text-left">
            <br />
            <h2>注意点</h2>
            <ul>
              <li>以下に挙げているのは「支援金」のストレッチゴールです</li>
              <li>
                各項目は発表当時の概略のみを記載しておりますので詳細は
                <a
                  href="https://www.kickstarter.com/projects/rabbitandbearstudios/eiyuden-chronicle-hundred-heroes"
                  target="_blank"
                  >Kickstarter の公式サイト</a
                >
                などをご確認ください
              </li>
            </ul>

            <h2>ゴール一覧</h2>

            <h5>達成額: ???万ドル</h5>
            <ul>
              <li>A Quiet Place (Companion Game)</li>
            </ul>
            <br />

            <h5>達成額: 450万ドル</h5>
            <ul>
              <li>セイを操作することができるDLCです</li>
              <li>帝国ガルディア側からの視点でゲームをプレイできます</li>
            </ul>
            <br />

            <h5>達成額: 440万ドル</h5>
            <ul>
              <li>本拠地が外部からの攻撃にさらされ、定期的に襲撃に遭います</li>
              <li>
                強固な城壁を築き、軍隊を鍛え、武器を揃え、彼らの襲撃から本拠地を守りましょう
              </li>
            </ul>
            <br />

            <h5>達成額: 435万ドル</h5>
            <ul>
              <li>3つ目のDLC</li>
              <li>本拠地を日本風あるいは中華風に彩ることができるDLCです</li>
              <li>あなただけの本拠地をデザインしてみてください</li>
            </ul>
            <br />

            <h5>達成額: 430万ドル</h5>
            <ul>
              <li>
                他のプレイヤーがネットワーク経由であなたの本拠地を自動的に訪れます
              </li>
              <li>あなたが寝ている間にあなたの軍と彼らの軍が戦います</li>
            </ul>
            <br />

            <h5>達成額: 425万ドル</h5>
            <ul>
              <li>英雄モード</li>
              <li>
                このストレッチゴールでは、一度潜ったら、クリアするかギブアップするまで引き返すことのできない高難易度のダンジョンに挑むことができるようになります
              </li>
              <li>
                奥に進むにつれて、英雄たちも疲労していき、本来の力を発揮できなくなります
              </li>
              <li>
                全ての英雄たちの力を結集して全フロアクリアを目指しましょう
              </li>
            </ul>
            <br />

            <h5>達成額: 400万ドル</h5>
            <ul>
              <li>村山吉隆さんによる電子版のスピンオフ小説</li>
              <li>バッカー向けのDLC</li>
            </ul>
            <br />

            <h5>達成額: 385万ドル</h5>
            <ul>
              <li>
                <a
                  href="https://scrapbox.io/100heroesstrong/110%E4%BA%BA%E7%9B%AE%E3%81%AE%E3%82%AD%E3%83%A3%E3%83%A9%E3%82%AF%E3%82%BF%E3%83%BC"
                  target="_blank"
                  >110人目のキャラクター（くノ一）</a
                >が追加されます
              </li>
              <li>2020/08/28 01:00 から24時間、支援者による投票が行われます</li>
              <li>
                投票は<a
                  href="https://forms.gle/B5JNQb9TmeqwESjx9"
                  target="_blank"
                  >Google フォーム</a
                >またはKickstarterページのメッセージ経由です
              </li>
            </ul>
            <br />

            <h5>達成額: 380万ドル</h5>
            <ul>
              <li>劇場システムが追加され、城塞都市に劇場が設置されます</li>
              <li>
                ときに著名な脚本家や俳優がその評判を聞き付け、傑作を紡いでいくかもしれません
              </li>
            </ul>
            <br />

            <h5>達成額: 370万ドル</h5>
            <ul>
              <li>ランキングモードが追加されます</li>
              <li>「使用回数が多いキャラ」のランキングです</li>
              <li>
                自分自身のランキングと世界中のプレイヤーのランキングの2つで構成されます
              </li>
            </ul>
            <br />

            <h5>達成額: 360万ドル</h5>
            <ul>
              <li>酒場でプレイできるカードバトルが追加されます</li>
              <li>
                最強のデッキを組み、オールラーン中で待ち構えるライバルキャラとバトルしましょう
              </li>
            </ul>
            <br />

            <h5>達成額: 342.5万ドル</h5>
            <ul>
              <li>特定のキャラに一騎打ちを挑めます</li>
              <li>洗練された一騎打ちシステムが見ものです</li>
            </ul>
            <br />

            <h5>達成額: 332.5万ドル</h5>
            <ul>
              <li>要塞都市に農場が追加されます</li>
            </ul>
            <br />

            <h5>達成額: 325万ドル</h5>
            <ul>
              <li>メリサのDLCが無料で追加されます</li>
            </ul>
            <br />

            <h5>達成額: 317.5万ドル</h5>
            <ul>
              <li>あなたの軍の「旗」を自由にデザインすることができます</li>
            </ul>
            <br />

            <h5>達成額: 312.5万ドル</h5>
            <ul>
              <li>あなたの軍の「旗」を自由にデザインすることができます</li>
              <li>109人目の仲間を決めるための支援者による投票が実施されます</li>
            </ul>
            <br />

            <h5>達成額: 310万ドル</h5>
            <ul>
              <li>物語の要所要所のシーンにてボイスが追加されます</li>
              <li>
                ノアと仲間たちによる波乱に満ちた冒険の旅路を素敵なボイスが彩ります
              </li>
              <li>ボイスは日本語と英語が選択可能です</li>
            </ul>
            <br />

            <h5>達成額: 307.5万ドル</h5>
            <ul>
              <li>対応言語にロシア語が追加されます</li>
            </ul>
            <br />

            <h5>達成額: 305万ドル</h5>
            <ul>
              <li>108人めの仲間となる Iris が参戦します</li>
            </ul>
            <br />

            <h5>達成額: 302.5万ドル</h5>
            <ul>
              <li>モンスターを育成するミニゲームが追加されます</li>
            </ul>
            <br />

            <h5>達成額: 300万ドル</h5>
            <ul>
              <li>仲間とともに温泉で疲れた体を癒すことができます</li>
            </ul>
            <br />

            <h5>達成額: 295万ドル</h5>
            <ul>
              <li>レースのミニゲームが追加されます</li>
            </ul>
            <br />

            <h5>達成額: 292.5万ドル</h5>
            <ul>
              <li>対応言語にブラジルポルトガル語が追加されます</li>
            </ul>
            <br />

            <h5>達成額: 290万ドル</h5>
            <ul>
              <li>106人めの仲間となるロディが追加されます</li>
            </ul>
            <br />

            <h5>達成額: 285万ドル</h5>
            <ul>
              <li>交易システムが追加されます</li>
            </ul>
            <br />

            <h5>達成額: 280万ドル</h5>
            <ul>
              <li>シークレットストレッチゴールです</li>
              <li>城塞都市に探偵事務所を開設することができます</li>
              <li>
                その事務所にいる探偵は超常能力を持ち、各キャラの知られざるエピソードをあぶり出すことができます
              </li>
              <li>
                この探偵は単なるゴシップには興味がなく、淡々と調査をこなしていきます
              </li>
              <li>各キャラの意外な一面にスポットが当たるかもしれません</li>
            </ul>
            <br />

            <h5>達成額: 275万ドル</h5>
            <ul>
              <li>新キャラであるメロールが追加されます</li>
            </ul>
            <br />

            <h5>達成額: 260万ドル</h5>
            <ul>
              <li>フルオーケストラ</li>
            </ul>
            <br />

            <h5>達成額: 250万ドル</h5>
            <ul>
              <li>対応言語に韓国語が追加されます</li>
            </ul>
            <br />

            <h5>達成額: 245万ドル</h5>
            <ul>
              <li>新キャラであるマキシムが追加されます</li>
            </ul>
            <br />

            <h5>達成額: 235万ドル</h5>
            <ul>
              <li>Top Battle ミニゲーム（現時点で詳細は不明です）</li>
            </ul>
            <br />

            <h5>達成額: 220万ドル</h5>
            <ul>
              <li>新キャラであるヒルディが追加されます</li>
            </ul>
            <br />

            <h5>達成額: 210万ドル</h5>
            <ul>
              <li>新キャラであるユーフェリウスVII世が追加されます</li>
            </ul>
            <br />

            <h5>達成額: 195万ドル</h5>
            <ul>
              <li>釣りのミニゲームが追加されます</li>
            </ul>
            <br />

            <h5>達成額: 180万ドル</h5>
            <ul>
              <li>新キャラであるペリエール・グリュムが追加されます</li>
            </ul>
            <br />

            <h5>達成額: 170万ドル</h5>
            <ul>
              <li>ギルドシステムが追加されます</li>
            </ul>
            <br />

            <h5>達成額: 165万ドル</h5>
            <ul>
              <li>対応言語に中国語（簡体・繁体）が追加されます</li>
            </ul>
            <br />

            <h5>達成額: 160万ドル</h5>
            <ul>
              <li>効果音の追加</li>
            </ul>
            <br />

            <h5>達成額: 150万ドル</h5>
            <ul>
              <li>周回プレイの要素の追加</li>
            </ul>
            <br />

            <h5>達成額: 125万ドル</h5>
            <ul>
              <li>料理ミニゲームの追加</li>
            </ul>
            <br />

            <h5>達成額: 100万ドル</h5>
            <ul>
              <li>家庭用ゲーム機で発売されます</li>
            </ul>
            <br />

            <h5>達成額: 75万ドル</h5>
            <ul>
              <li>城塞都市に「街」を作れます</li>
            </ul>
            <br />

            <h5>達成額: 50万ドル</h5>
            <ul>
              <li>最低達成目標額です</li>
              <li>この額を達成するとプロジェクトがスタートします</li>
            </ul>
            <br />

            <h6>
              ※詳細は
              <a
                href="https://scrapbox.io/100heroesstrong/%E3%82%B9%E3%83%88%E3%83%AC%E3%83%83%E3%83%81%E3%82%B4%E3%83%BC%E3%83%AB"
                target="_blank"
                >Scrapbox</a
              >
              にも記載してあります
            </h6>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Goal from '@/components/StretchGoals/Goal'

export default {
  component: {
    Goal,
  },
}
</script>

<style scoped>
.columns {
  margin: 1px;
}

.column {
  padding: 1px;
}
</style>
